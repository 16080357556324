@font-face {
  font-family: "Neo Sans Std";
  font-weight: 400;
  src: url(assets/fonts/NeoSansStd-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Neo Sans Std";
  font-weight: 700;
  src: url(assets/fonts/NeoSansStd-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Neo Sans Std";
  font-weight: 200;
  src: url(assets/fonts/NeoSansStd-Light.otf) format("opentype");
}
